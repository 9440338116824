<template>
    <div class="right-side">
        <h2>{{ $t('MainMenu.video-tutorials') }}</h2>

        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne" type="button" data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <div>
                        <img src="@/assets/img/icons/video.svg" alt="">
                        <p class="btn ">
                            1. Регистрация на портале
                        </p>
                    </div>
                    <div class="icon__block">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>

                <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div class="card-body text-center">
                        <iframe width="80%" height="400" src="https://www.youtube.com/embed/92lTJyYS5YE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseOne">
                    <div>
                        <img src="@/assets/img/icons/video.svg" alt="">
                        <p class="btn ">
                            2. Заполнение профиля
                        </p>
                    </div>
                    <div class="icon__block">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                    data-parent="#accordionExample">
                    <div class="card-body">
                        <iframe width="80%" height="400" src="https://www.youtube.com/embed/5ERdPDotKF8"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseThree"
                    aria-expanded="true" aria-controls="collapseOne">
                    <div>
                        <img src="@/assets/img/icons/video.svg" alt="">
                        <p class="btn ">
                            3. Подключенние тарифа
                        </p>
                    </div>
                    <div class="icon__block">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div class="card-body">
                        <iframe width="80%" height="400" src="https://www.youtube.com/embed/R5khOO6ZHVg"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseFour"
                    aria-expanded="true" aria-controls="collapseOne">
                    <div>
                        <img src="@/assets/img/icons/video.svg" alt="">
                        <p class="btn ">
                            4. Подписка на рассылку уведомлений
                        </p>
                    </div>
                    <div class="icon__block">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div class="card-body">
                        <iframe width="80%" height="400" src="https://www.youtube.com/embed/ei_IlR1Oq-A"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseFive"
                    aria-expanded="true" aria-controls="collapseOne">
                    <div>
                        <img src="@/assets/img/icons/video.svg" alt="">
                        <p class="btn ">
                            5. Аналитика Тариф «‎Бизнес»
                        </p>
                    </div>
                    <div class="icon__block">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingTwo"
                    data-parent="#accordionExample">
                    <div class="card-body">
                        <iframe width="80%" height="400" src="https://www.youtube.com/embed/aToMlHD2ZDE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseSix"
                    aria-expanded="true" aria-controls="collapseOne">
                    <div>
                        <img src="@/assets/img/icons/video.svg" alt="">
                        <p class="btn ">
                            6. Аналитика Тариф «‎Аудитор»
                        </p>
                    </div>
                    <div class="icon__block">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div id="collapseSix" class="collapse" aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div class="card-body">
                        <iframe width="80%" height="400" src="https://www.youtube.com/embed/kiyHSBaLzLQ"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseSev"
                    aria-expanded="true" aria-controls="collapseOne">
                    <div>
                        <img src="@/assets/img/icons/video.svg" alt="">
                        <p class="btn ">
                            7. Анализ медианных цен
                        </p>
                    </div>
                    <div class="icon__block">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div id="collapseSev" class="collapse" aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div class="card-body">
                        <iframe width="80%" height="400" src="https://www.youtube.com/embed/-R-43ZsxsMs"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="true" aria-controls="collapseOne">
                    <div>
                        <img src="@/assets/img/icons/video.svg" alt="">
                        <p class="btn ">
                            8. База технических спецификаций
                        </p>
                    </div>
                    <div class="icon__block">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div id="collapseEight" class="collapse" aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div class="card-body">
                        <iframe width="80%" height="400" src="https://www.youtube.com/embed/Jgy0qeWXlzY"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    @media (max-width: 770px) {
        .card-body iframe {
            height: 200px;
        }
    }
</style>